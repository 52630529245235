<template>
  <v-layout column style="width: 500px">
    <div v-if="isCustomerSelected && showButtons" class="padding-x-13 margin-b-0">
      <CustomerSignUpEmailDetail
        :customer="customer"
      />
      <v-divider />
    </div>
    <TripContactSidebarUserSelector
      :id="`update-customer-user-selector`"
      v-model="customer"
      user-title="Customer"
      style="margin: -10px 0 -20px -14px"
      updating-customer
      @set-trip-contact="setCustomer"
      @show-buttons="(value) => (showButtons = value)"
    />
    <div style="height: 142px; width: 100%" />
    <v-btn
      :id="`update-customer-button`"
      class="update-customer--action-btn"
      :class="{
        'update-customer--action-btn--disabled': !isCustomerSelected,
      }"
      color="primary"
      :style="`background-color: ${
        isCustomerSelected ? '#3B9CF1' : '#ACCBE3'
      } !important;`"
      :disabled="!isCustomerSelected"
      @click="submit"
    >
      Save
    </v-btn>
  </v-layout>
</template>

<script>
import TripContactSidebarUserSelector from './TripContactSidebarUserSelector.vue'
import CustomerSignUpEmailDetail from './CustomerSignUpEmailDetail.vue'
import admin from '@/services/admin'
import customers from '@/services/customers'
import { mapActions } from 'vuex'
import { deepClone } from '@/utils/deepClone'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    TripContactSidebarUserSelector,
    CustomerSignUpEmailDetail,
  },
  provide: {
    isInSidebarDialog: true,
  },
  props: {
    managedId: {
      type: String,
      required: true,
    },
    currentCustomer: {
      type: Object,
      required: true,
    }
  },
  computed: {
    isCustomerSelected() {
      return this.customer?.id !== null
    }
  },
  data() {
    return {
      customer: {
        id: null,
        firstName: '',
        lastName: '',
        email: '',
        bookings: '',
        customerId: null,
        industryId: null,
      },
      showButtons: true,
    }
  },
  created() {
    this.customer = deepClone(this.currentCustomer)
    this.customer.id = this.customer.customerId
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert'
    }),
    async setCustomer(customer) {
      if (customer.id) {
        const customerResponse = await customers.getCustomer(customer.id)
        this.customer = customerResponse?.data?.customer
        this.customer.id = this.customer.customerId
      } else {
        this.customer = customer
      }
    },
    async submit() {
      try {
        const result = await admin
          .updateReservationCustomerId({
            managedId: this.managedId,
            customerId: this.customer?.id,
          })
        setTimeout(() => {
          this.showAlert({
            message: 'Successfully Updated Customer.',
            type: 'success'
          })
        }, 200)
        EventBus.$emit('refresh-detail')
        this.$store.dispatch('app/closeDialog')
      } catch (e) {
        this.showAlert({
          message: 'Error Updating Customer.',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.update-customer {
  height: 100%;
  width: 500px;

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    bottom: 0;
    width: 500px !important;
    height: 71px !important;
    padding: 24px 0;
    width: inherit;
    border-radius: 0 !important;
    z-index: 100;
    right: 0;
    margin: 0;

    &.v-btn--disabled {
      background-color: $accent !important;
    }
  }
}

</style>
